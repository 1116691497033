/* Colour palette */

$primary-dark-green: #003C4E;
$primary-light-green: #069DB4;
$primary-red: #D61B1B;
$primary-black: black;
$primary-white: white;

$secondary-light-grey: #E0E0E0;
$secondary-grey:grey;

$window-background-colour: #f0f0f0;
$content-background-colour: white;

$radar-background-colour: #E0ECEF;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
  color: $primary-black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $window-background-colour;
  overflow: hidden;
}

input {
  width: 100%;
  font-size: 100%; 
  padding-top: 5px;
  padding-left: 10px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

@mixin hover-effect {
  filter: brightness(86%);
}

@mixin card-hover {
  @include hover-effect;
}

* {
  box-sizing: border-box;
}

a {
  color: $primary-red;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.avatar {
  float: left;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: $primary-white;
  font-size: 15px;
  text-align: center;;
  background-color: $primary-dark-green;
  line-height: 32px;
  margin-right: 5px;
}

.headerTeamName {
  font-size: 20px;
  margin-top: 5px;
}

.avatarSmall {
  float: left;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: $primary-white;
  font-size: 8px;
  text-align: center;;
  background-color: $primary-dark-green;
  line-height: 28px;
  margin-right: 3px;
}

.avatarImage {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.buttonContainer {
  margin-left: 10px;
  margin-right: 10px;
}


.header {
  position: relative;
  background-image: linear-gradient(to bottom right,$primary-dark-green,$primary-dark-green);

  height : 50px;
  display: flex;
  align-items: center;
  justify-content:center;
  text-transform: capitalize; 
  overflow: hidden;
}

.headerIcon {
  position: relative;
  height: 50px;
  z-index: 0;
}

.headerIconContainer {
  position: absolute;
  margin: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  text-align: center;
}

.headerTitle {
  // background-image: linear-gradient(to bottom right,$primary-red-dark, $primary-red-dark);
  //background-image: linear-gradient(to bottom right,$primary-red-old, $primary-red-old);

  background: $primary-white;
  color: black;
  
  height : 50px;
  text-align: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid $secondary-light-grey;
}

.navTitle {
  color: $primary-black;
  text-align: center;
  margin-left: 0px;
  width: 100%;
  font-size: 20px;
  position: relative;
  top: 13px;
}


.btn {
  /* default for <button>, but useful for <a> */

  color: $primary-white;
  /*  background-color: $secondary-grey; */
  background-color: $secondary-grey;

  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  width: 100%;

  height: 44px;
  border: solid 1px transparent;
  border-radius: 10px;

  /* create a small space when buttons wrap on 2 lines */
  margin: 2px 0;

  /* size comes from text & padding (no width/height) */
  padding: 0.5em 1em;

  outline: none;
}

.loadingSpinnerContainer {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinnerImage {
  position: absolute;
  top: 100px;
  width: 80px;
  height: 80px;
}

.btn-small {
  color: $primary-white;
  /*  background-color: $secondary-grey; */
  background-color: $secondary-grey;

  /* default for <button>, but useful for <a> */
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  border: solid 1px transparent;
  border-radius: 10px;

  /* create a small space when buttons wrap on 2 lines */
  margin: 2px 0;

  /* size comes from text & padding (no width/height) */
  padding: 0.5em 1em;
  outline: none;
}

/* Firefox: removes the inner border shown on focus */
.btn::-moz-focus-inner {
  border: none;
}

/* old-school "down" effect on clic + color tweak */
.btn:active {
  transform: translateY(1px);
  filter: saturate(150%);
}

.btn-primary {
  /* make sure colors have enough contrast! */
  color: $primary-white;
/*  background-color: $secondary-grey; */
  background-color: $primary-dark-green;

}

/* inverse colors on mouse-over and focus */
.btn:hover,
.btn:focus,
.btn-small:hover,
.btn-small:focus,
.btn-primary:hover,
.btn-primary:focus {
  @include hover-effect;
}

.btn-google {
  /* make sure colors have enough contrast! */
  color: #000000;
  background-color: $primary-white;
  outline: none;
  display: flex;
  justify-content: center;
  border: 1px solid black;
  border-radius: 18px;
  padding-top: 12px;
}

.loginTroubleLoggingIn {
  text-align: center;
}

.onboardingSeparator {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
}

.dataGridContainer {
  margin-bottom: 30px;
}

.rdg-row-even {
  background-color: #eeeeee;
  color: black;
}

.rdg-row-odd {
  background-color: $primary-white;
  color: $primary-black;
}

.rdg-header-row {
  background-color: $primary-red;
  color: $primary-black;
}

.react-tabs {
  padding-top: 10px;
}


.rdg {
  border: none !important;
}

.sellingImage {
  width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sellingMessage {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.listingSummary {
  position: relative;
  width: 100%;
  border-bottom: 1px solid $secondary-light-grey;
  height: 60px;
}

.listingSummaryTitle {
  position: absolute;
  top: 10px;
  left: 90px;
  width: 320px;
  font-size: 15px;
  font-weight: 600;
}

.listingSummarySubtitle {
  position: absolute;
  top: 30px;
  left: 90px;
  font-size: 15px
}

.listingSummaryImage {
  width: 80px;
  height: 60px;
}

.selectListingTypeHeader {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;

}

.selectListingType {
  position: relative;
  width: 100%;
  height: 100px;
}

.selectListingTypeIcon {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 10px;
  left: 10px;
}

.selectListingTypeIconContainer {
  position: absolute;
  width: 70px;
  height: 70px;
  top: 10px;
  left: 10px;
  box-shadow: 1px 1px 2px 2px #ccc;
  border-radius: 10%; /*supported by all latest Browser*/
  -moz-border-radius:10%; /*For older Browser*/
  -webkit-border-radius:10%;/*For older Browser*/
}

.selectListingTypeName {
  position: absolute;
  width: 320px;
  top: 20px;
  left: 100px;
  font-size: 16px;
  font-weight: 500;
}

.selectListingTypeDescription {
  position: absolute;
  width: 320px;
  top: 45px;
  left: 100px;
  font-size: 16px;
  font-weight: 500;
}

.formFieldName {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;

  font-size: 16px;
  font-weight: 500;
}

.suburbSelectorBox {
  height: 60px;
  background: $radar-background-colour;
  margin-top: 10px;
  text-align: center;
  padding-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.formFieldInput {
  margin-left: 10px;
  margin-right: 10px;
}

.photosSummary {
  position: relative;
  width: 100%;
  height: 180px;
  text-align: center;
  background: $radar-background-colour;
}

.photosSummaryText {
  position: relative;
  top: 80px;
  font-size: 16px;
}

.photosSummaryImage {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 240px;
  height: 180px;
}

.photosSummaryLabel {
  position: absolute;
  right: 110px;
  top: 80px;
}

.message-popup-content {
  margin: auto;
  background: $primary-white;
  width: 340px !important;
  height: 130px !important;
  padding: 0px !important;
  border: none !important;
}

.messagePopupMessage {
  position: absolute;

  width: 300px;
  top: 55px;
  height: 30px;
  width: 100%;
  text-align: center;
  background-color: $primary-white;

  text-align: center;
  font-size: 16px;
  border-radius: 5px;
}

.messagePopupOKButton {
  position: absolute;

  left: 126px;
  bottom: 7px;
}

.address-selector-popup-content {
  margin: auto;
  background: $primary-white;
  width: 380px !important;
  height: 500px !important;
  padding: 0px !important;
  border: none !important;
}

.addressCompletion {
  width: 100%;
  font-size: 16px;
  height: 30px;
  margin-bottom: 10px;
  border-bottom: 1px solid $secondary-light-grey;
}

.image-uploader-popup-content {
  margin: auto;
  background: $primary-white;
  width: 520px !important;
  padding: 0px !important;
  border: none !important;
}

.imageUploaderImageList {
  width: 100%;
  height: 570px;
  overflow-y: auto;
}

.imageUploaderSpinnerContainer {
  height: 220px;
}

.imageUploaderImageContainer {
  position: relative;
  height: 240px;
  margin-bottom: 10px;
}

.imageUploaderImage {
  float: left;
  width: 320px;
  height: 240px;
  margin-left: 30px;
}

.imageUploaderImageDelete {
  position: absolute;
  float: right;
  width: 40px;
  height: 40px;
  top: 100px;
  right: 60px;
}

.imageUploadButtonTable {
  float: left;
  width: 100%;
  height: 100px;
}

.signupFormFields {
  margin-left: 10px;
  margin-right: 10px;
}





