/* Old Colour palette 

$primary-red: #AD2B2B;
$primary-green: #5ED8BC;
$primary-white: white;
$primary-black: black;
$primary-red-bright: #BA2026;
$primary-red-dark: #851537;

$secondary-dark-grey: #474448;
$secondary-grey: grey;
$secondary-light-grey: lightgrey;
$secondary-blue: #78A1BB; 

*/

$primary-red: #ea2952;
$primary-green: #00D4FF;
$primary-white: white;
$primary-black: #14223D;
$primary-red-bright: #FF2E92;
$primary-red-dark: #ea2952;

$secondary-dark-grey: #474448;
$secondary-grey: grey;
$secondary-light-grey: lightgrey;
$secondary-blue: #7b39ED; 

$window-background-colour: #f0f0f0;
$content-background-colour: white;

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 50vmin;
  pointer-events: none;
}

.App-header {
  position: relative;
  background-color: #282c34;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $primary-white;
  top: 0;
  width: 100%;
  height: 50px;
}

.App-header-logo {
  position: absolute;
  left: 10px;
  top: 8px;
  width: 32px;
  height: 32px;
}

.Popup-header {
  width: 100%;
  height: 40px;
  font-size: 18px;
  background-color: $primary-red;
  color: $primary-white;
  text-align: center;
  line-height: 40px;
}

.App-header-back {
  position: absolute;
  left: 12px;
  top: 10px;
  width: 17px;
  height: 26px;
  background-image: url('../public/icons/back-arrow.png');
  background-size: cover;
  z-index: 1;
}

.App-header-avatar {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 32px;
  height: 32px;
}

.App-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Height of the footer*/ 
    height: 60px;
    background: $primary-white;
    overflow: hidden;
    border-top: 1px solid $secondary-light-grey;

}

.App-sidebar {
  position: absolute;
  width: 240px;
  left: 0px;
  top: 50px;
  bottom: 0px;
  background: $primary-white;
  overflow: auto;
}

.App-link {
  color: $secondary-blue;
}

.headerIcon {
  height: 46px;
  padding-top: 5px;
}

.App-content {
  position: absolute;
  overflow: auto;
  top: 50px; 
  bottom: 0px;
  right: 0px;
/*  background-color: blue; */
}

.App-content-column {
  max-width:400px;
  margin: auto;
}

.App-pageframeOuter {
  position: fixed;
  overflow: auto;
 /* background-color: red; */
  width: 100%;
  height: 100%;
  background-color: primary-white;
}

.App-pageframeInner {
  /* this needs to be position: relative so we can use absolute
     positioning of child elements */
  position: relative; 
  height: 100%;

  overflow: auto;
  margin: auto;
 background-color: $content-background-colour;

}

.App-root {
  overflow: hidden;
}

.accountSettingsToggle {
  display: inline-block;
  width: 80px;
  padding-top: 5px;
  padding-left: 20px;
}

.accountSettingsName {
  display: inline-block;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 767px) {
  .App-sidebar {
    display: none;
  }

  .App-content {
    left: 0px;
    bottom: 60px;
    margin: 0 auto;
  }

  .tabBarContainer {
    width : 100%;
    height: 60px;
  }

  .tabBarItem {
    width : 25%;
    height: 100%;
    display: inline-block;
    text-align: center;
  }

  .tabBarIconContainer {
    width:100%;
    height: 40px;
    width: 100%;
    display: block;

  }

  .tabBarLabelContainer {
    width:100%;
    height: 20px;
    text-align: center;
  }

  .tabBarIcon {
    position: relative;
    top: 8px;
    width: 28px;
    height: 28px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .tabBarLabel {
    text-align: center;
    font-size: 12pt;
    position: relative;
    bottom: 2px;
    color: $secondary-dark-grey;
  }


  .tabBarLabelDisabled {
    text-align: center;
    font-size: 12pt;
    position: relative;
    bottom: 2px;
    color: grey;
  }
}

@media only screen and (min-width: 768px) {

  .tabBarContainer {
    position: relative;
    top: 0px;
    left: 0px;
    height: 160px;
  }

  .tabBarItem {
    position: relative;
    height: 40px;
    text-align: center;
  }

  .tabBarItem:hover {
    background-color: #eeeeee;
  }

  .tabBarIconContainer {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 5px;
  }

  .tabBarLabelContainer {
    position: absolute;
    left: 45px;
    top: 0px;
    height: 40px;
    text-align: center;
  }

  .tabBarIcon {
    position: relative;
    top: 8px;
    width: 28px;
    height: 28px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .tabBarLabel {
    position: relative;
    text-align: center;
    font-size: 13pt;
    top: 10px;
    color: $secondary-dark-grey;
  }

  .tabBarLabelDisabled {
    position: relative;
    text-align: center;
    font-size: 13pt;
    color: grey;
    top: 10px;
  }

} 

@media only screen and (min-width: 768px) and (max-width: 823px) {
  .App-footer {
    display: none;
  }


  .App-content {
    left:240px;
    bottom: 0px;
  }
}
@media only screen and (min-width: 824px) {

  .App-footer {
    display: none;
  }

  .App-sidebar {
    border-left: 1px solid $secondary-light-grey;
  }

  .App-content {
    width: 540px;
    right: 0px;
    bottom: 0px;
    border-left: 1px solid $secondary-light-grey;

    border-right: 1px solid $secondary-light-grey;

  }

  .App-pageframeInner {
    height: 100%;
    width: 784px;
  }
}


